.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-layout-content {
  background: #fff;
  padding: 0px;
  min-height: 280px;
}
.logo {
  width: 190px;
  height: 40px;
  background: rgba(255, 255, 255, 0.5);
  margin: 13px 10px 8px 0px;
  float: left;
}
.imglogo {

  width:90%;
display: block;
margin:auto;
}
.logotb {
  width: 190px;
  height: 40px;
  background: rgba(255, 255, 255, 0.5);
  margin: 13px 0px 0px 0;
  float: left;
}
.imglogotb {

  width:53%;
display: block;
margin:auto;
}

.grid-container{
  display: flex;
   flex-direction: row;
   flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  flex-basis: 30%;
}
.ant-layout-header{
  padding: 0 0px !important;
  background: white !important;
}
.ant-layout-content{
  padding: 0 0px !important
}