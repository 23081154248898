.grid-container {
    /*display: grid;
    grid-template-columns: auto auto auto;
    background-color: #2196F3;
    padding: 10px;*/
    vertical-align: middle;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    width: 13.5vw;
    height: 8vw;
    padding: 5px;
    /*font-size: 30px;*/
    /*flex: 0 1 calc(20% - 8px); /* <-- adjusting for margin */
    text-align: center;
  }

  html,
body {
	margin : 0;
	padding : 0;
}



@keyframes color {
  0% {
    background-color: rgb(255, 187, 0);
  }
  50% {
    background-color: #ffa600;
  }
  100% {
    background-color: rgb(255, 187, 0);
  }
}

@keyframes colorcrit {
  0% {
    background-color: rgba(255, 0, 0, 0.8);
  }
  50% {
    background-color: #ff3a3aab;
  }
  100% {
    background-color: rgba(255, 0, 0, 0.8);
  }
}

.popup {
  position:absolute;
  z-index:20000;
  top: 0px;
  left: 0px;
  display: none;
background-color:#dd8;
border: 1px solid;
}

.land {
  fill: #b9b9b9;
stroke: white;
stroke-width: 1.5;
stroke-miterlimit: 4;
}
.coast {
  stroke-width: 0.5;
}
.circle {
  opacity: 0;
fill-opacity: 1;
stroke: black;
stroke-width: 0.5;
stroke-opacity: 1;
}
path:hover {
  opacity: 0.5;
}

.st0{opacity:1;fill:#FFFFFF;fill-opacity:"0";stroke:#000000;stroke-miterlimit:10;}
.st1{fill:none;stroke:#000000;stroke-miterlimit:10;}


.cls-1 {
  fill: none;
}

.cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-2, .cls-20, .cls-21, .cls-23, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
  isolation: isolate;
}

.cls-22, .cls-3 {
  fill: #dddddd;
  fill-opacity: 0.34902;
  fill-rule: evenodd;
}

.cls-4 {
  font-size: 24px;
}

.cls-11, .cls-12, .cls-16, .cls-17, .cls-18, .cls-19, .cls-20, .cls-23, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
  font-family: ArialMT, Arial;
}

.cls-5 {
  font-size: 19px;
}

.cls-6 {
  font-size: 21px;
}

.cls-7 {
  font-size: 16px;
}

.cls-8, .cls-9 {
  font-size: 11px;
}

.cls-13, .cls-14, .cls-15, .cls-21, .cls-9 {
  font-family: Arial-BoldMT, Arial;
  font-weight: 700;
}

.cls-10 {
  letter-spacing: -0.05518em;
}

.cls-11 {
  font-size: 15px;
}

.cls-12 {
  font-size: 32px;
}

.cls-13, .cls-18 {
  font-size: 8px;
}

.cls-14, .cls-16 {
  font-size: 9px;
}

.cls-15, .cls-20 {
  font-size: 5px;
}

.cls-17 {
  font-size: 27px;
}

.cls-19 {
  font-size: 4px;
}

.cls-21 {
  font-size: 13px;
}

.cls-22 {
  stroke: #000;
}

.cls-23 {
  font-size: 7px;
}
.grid-item-ok {fill: rgba(0, 255, 0, 0.8)}
.grid-item-blue {animation: colbb 3s linear infinite;}
.grid-item-warning {animation: colyy 2s linear infinite;}
.grid-item-critical {animation: colrr 0.5s linear infinite;}
  @keyframes colyy {
    0% {
      fill: rgb(255, 187, 0);
    }
    50% {
      fill: #ffa600;
    }
    100% {
      fill: rgb(255, 187, 0);
    }
    }

    @keyframes colrr {
      0% {
        fill: rgba(255, 0, 0, 0.8);
      }
      50% {
        fill: #ff3a3aab;
      }
      100% {
        fill: rgba(255, 0, 0, 0.8);
      }
      }

      @keyframes colbb {
        0% {
          fill: rgb(0, 110, 255);
        }
        50% {
          fill: #0400ff;
        }
        100% {
          fill: rgb(0, 110, 255);
        }
        }

